import React from "react";
import { THEMES } from "../context/withApp";
import Page from "../components/Page/Page";
import SEO from "../components/SEO/SEO";
import type from '../styles/typography.module.scss';

const NotFoundPage = props => (
  <Page { ...props.page} theme={ THEMES.dark }>
    <SEO title="404: Not found" />
    <h1>Not found.</h1>
    <p className={type.lead}>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Page>
)

export default NotFoundPage
